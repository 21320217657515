import classNames from 'classnames'

const LogoCarousel = ({
  logoList,
  direction
}: {
  logoList: {
    url: string
    name: string
  }[]
  direction?: 'left' | 'right' | 'up' | 'down' | undefined
}) => {
  return (
    <div className="logos">
      <div className={classNames('logos-slide', direction === 'right' ? 'logos-slide-right' : 'logos-slide-left')}>
        {logoList.map((logo, index) => (
          <img alt={logo.name} src={logo.url} key={index} className="mx-auto h-28 w-auto" />
        ))}
      </div>
      <div className={classNames('logos-slide', direction === 'right' ? 'logos-slide-right' : 'logos-slide-left')}>
        {logoList.map((logo, index) => (
          <img alt={logo.name} src={logo.url} key={index} className="mx-auto h-28 w-auto" />
        ))}
      </div>
    </div>
  )
}

export { LogoCarousel }
